<template>
	<div class="home">


		<div class="about-bg">
			<!-- <img  v-if="banners.length" v-imgUrl="banners[1].url"> -->
			
			<img   v-imgUrl="banners.about" class="nav-bg">
			<div class="bg"></div>
			<div class="title">
				<h2>关于本站</h2>
				<em>——记录分享技术和生活的博客</em>
				<em>构建技术：thinkphp & vue</em>
			</div>
		</div>
		<div class="about">
			<!-- <p>在我人生迷茫时接触到PHP，它就像一颗种子在我的生活中慢慢的开出来花朵。后来不断的学习新的技术，</p>
			<p>经过几年的工作和学习，就想将自己的技术积累写下来，而这个博客就诞生了，我会用他来记录我的学习和生活</p>
			<p>因为感受着不断成长的自己，会让我非常心安。</p> -->
			<p>建站初衷是因为工作中很多技术点记不住，找起来很麻烦。就想有一个专门记录技术点的工具，后来经过慢慢的积累文章越来越多，就希望拿出来和大家分享。</p>
			<p>本站仅用于技术文章的学习和分享，不做文章版权限制。大家可以放心转载引用。如果文章对你有帮助，希望可以给个 <i class="iconfont lv-icon-yijin13-zan" style="color: red;"></i></p>
			
			<p>本站文章内容都是经过博主亲自实践后进行编写，如有错误、疑问、技术交流等，欢迎留言或者联系博主
			
			<Tooltip content="复制邮箱" >
			  <i class="iconfont icon-youxiang" style="color: red;" @click="copy()"></i>
			</Tooltip>
			
			</p> 
			
		</div>

	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from "vuex"
	export default {
		data() {
			return {
				skills: []
			}
		},
		computed: {
			...mapGetters([
				'banners'
			])
		},
		created() {
			
		},
		methods: {

			
			  copy(){

						const input = document.createElement('input') // 创建input对象
				       input.value = '2331013703@qq.com' // 设置复制内容
				       document.body.appendChild(input) // 添加临时实例
				       input.select() // 选择实例内容
				       document.execCommand('Copy') // 执行复制
				       document.body.removeChild(input) // 删除临时实例
				       this.$Message.success('复制成功！')
			
			}
		}
	}
</script>

<style lang="scss" scoped>
	.banner {
		max-width: 800px;
		margin: 0 auto;

		img {
			width: 100%;
		}
	}

	.webbase {
		text-align: center;
		padding: 50px 20px;

		h2 {
			font-weight: 300;
			font-size: 28px;
		}

		h3 {
			font-weight: 300;
			font-size: 22px;
		}
	}

	.make {
		padding: 40px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 20px;
		line-height: 30px;
		font-family: cursive;
		font-weight: bold;

		img {
			max-width: 100px;
		}

		a i {
			font-size: 28px;
		}

		.lv-icon-aixin {
			margin: -30px 40px 0;
			color: red;
			font-size: 26px;
		}

		.laravel {
			font-family: none;
			color: #fa5039;
		}

		.vue {
			font-family: none;
			color: #3cb884;
		}
	}

	.about-bg {
		width: 100%;
		height: 600px;
		position: relative;

		img {
			width: 100%;
			position: absolute;
			top: 0;
			-o-object-fit: cover;
			object-fit: cover;
			height: 100%;
		}

		.bg {
		background: #a3b0bf;
			mix-blend-mode: multiply;
			height: 100%;
		}

		

		.title {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			color: #fff;

			h2 {
				position: relative;
				font-size: 34px;
				font-weight: 300;
				padding-bottom: 30px;
				margin-bottom: 30px;
			}

			h2:after {
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 80px;
				height: 2px;
				margin-left: -40px;
				content: '';
				background-color: #FF5722;
			}

			em {
				color: #d0d6da;
				font-size: 16px;
				font-style: oblique;
				line-height: 40px;
			}
		}
	}

	.about {
		text-align: center;
		position: relative;
		line-height: 26px;
		font-size: 18px;
		padding: 60px 20px;
		max-width: 700px;
		margin: auto;

		p {
			padding-bottom: 10px;
		}

		em {
			color: #9eabb3;
			font-size: 16px;
			font-style: oblique;
			line-height: 40px;
		}

		a {
			margin: 0 10px;
		}

		h3 {
			font-size: 20px;
			font-weight: 400;
			padding-top: 30px;
			color: #f7576c;
		}
	}

	.skills {
		max-width: 800px;
		text-align: center;
		margin: auto;

		p {
			font-size: 20px;
			color: #f7576c;
		}

		img {
			max-width: 88px;
			margin: 3px;
		}
	}


	@media screen and (max-width: 750px) {
		.about-bg {
			height: 300px;
		}

		.about {
			padding: 40px 20px;
			font-size: 16px;

			em {
				font-family: cursive;
				font-size: 24px;
			}
		}

		.make .laravel,
		.make .vue {
			font-family: cursive;
		}

		.about-bg .title {
			h2 {
				font-size: 26px;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			em {
				font-family: cursive;
			}
		}

		.skills img {
			max-width: 66px;
		}
	}

	@media screen and (min-width: 1368px) {
		.about-bg:first-child {
			height: 700px;
		}
	}

	@media screen and (min-width: 1660px) {
		.about-bg:first-child {
			height: 630px;
		}
	}
</style>
